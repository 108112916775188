.anchorText {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 theme('spacing.1');

  &:focus {
    outline: none;
    box-shadow: theme('boxShadow.0');
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

.iconLeft {
  margin-right: theme('spacing.1');
}

.iconRight {
  margin-left: theme('spacing.1');
}

[data-disabled='true'] {
  &.anchorText {
    cursor: not-allowed;
    pointer-events: none;
    color: theme('colors.tertiary200') !important;
  }
}

[data-color='primary'] {
  &.anchorText {
    color: theme('colors.primary500');

    &:hover {
      color: theme('colors.primary600');
    }

    &:active {
      color: theme('colors.primary700');
    }
  }
}

[data-color='secondary'] {
  &.anchorText {
    color: theme('colors.secondary500');

    &:hover {
      color: theme('colors.secondary600');
    }

    &:active {
      color: theme('colors.secondary700');
    }
  }
}

[data-color='tertiary'] {
  &.anchorText {
    color: theme('colors.tertiary500');

    &:hover {
      color: theme('colors.tertiary600');
    }

    &:active {
      color: theme('colors.tertiary700');
    }
  }
}

[data-size='small'] {
  &.anchorText {
    @apply text-caption-sm-semibold;
    height: 18px;

    & svg {
      width: theme('spacing.2');
      max-height: theme('spacing.2');
    }

    &:focus {
      border-radius: 9px;
    }
  }
}

[data-size='medium'] {
  &.anchorText {
    @apply text-caption-lg-semibold;
    height: 21px;

    & svg {
      width: theme('spacing.3');
      max-height: theme('spacing.3');
    }

    &:focus {
      border-radius: 10.5px;
    }
  }
}

[data-size='large'] {
  &.anchorText {
    @apply text-body-sm-semibold;
    height: 24px;

    & svg {
      width: theme('spacing.4');
      max-height: theme('spacing.4');
    }

    &:focus {
      border-radius: 12px;
    }
  }
}
